<template>
    <div v-if="manager && !loading">
        <div v-if="$store.state.auth.user.role === 'agence'" class="mb-3">
            <router-link to="/agencies"><i class="fas fa-arrow-circle-left" /> Retour</router-link>
        </div>

        <b-tabs size="is-medium" class="block">
            <b-tab-item :label="'Mes consultants (' + consultantsTotal + ')'" icon="building">
                <div class="box">
                    <ConsultantsTable ref="consultants" />
                </div>
            </b-tab-item>
            <b-tab-item :label="'Clients (' + clientsTotal + ')'" icon="address-book">
                <div class="mb-3">
                    <CreateAgencyClientButton @reload="reloadClients()" />
                </div>

                <div class="box">
                    <AgencyClientsTable ref="clients" />
                </div>
            </b-tab-item>
        </b-tabs>
    </div>
</template>

<script>
    import { mapActions, mapState } from 'vuex'
    import ConsultantsTable from '@/components/Agency/Table/ConsultantsTable.vue'
    import CreateAgencyClientButton from '@/components/Agency/Button/CreateAgencyClientButton.vue'
    import AgencyClientsTable from '@/components/Agency/Table/AgencyClientsTable.vue'

    export default {
        title: 'Manager',
        components: { AgencyClientsTable, CreateAgencyClientButton, ConsultantsTable },
        data: () => ({
            selectedManager: null,
            totalPaid: 0,
            totalContents: 0,
            loading: true
        }),
        created() {
            this.$store.commit('consultants/setScope', 'manager')

            let config = this.$store.state.consultants.consultantsConfig
            delete config.filters.without_manager

            const endpoint = this.getAgencyFromUser()

            if (this.$store.state.auth.user.role === 'manager') {
                endpoint.then(data => {
                    this.$store.commit('consultants/setAgency', {
                        id: data.manager.agency_id
                    })
                    this.selectedManager = data.manager
                })
            } else {
                endpoint.then(data => {
                    this.$store.commit('consultants/setAgency', data.agency)
                    this.selectedManager = { id: this.$route.query.id }
                })
            }

            endpoint.catch(e => {
                console.log(e)
                this.$store.dispatch('toastError', e)
            })

            endpoint.then(() => {
                this.fetch()
            })
        },
        computed: {
            ...mapState('consultants', ['manager', 'clients']),
            month: {
                get() {
                    return this.$store.state.consultants.month
                },
                set(value) {
                    this.$store.commit('consultants/setMonth', value)
                    this.fetch()
                }
            },
            clientsTotal() {
                return this.clients ? this.clients.total : 0
            },
            consultantsTotal() {
                return this.manager ? this.manager.consultants.length : 0
            }
        },
        methods: {
            ...mapActions('consultants', ['getManager', 'getAgencyFromUser']),
            getAgency() {
                this.getAgencyFromUser()
                    .then(data => {
                        this.$store.commit('consultants/setAgency', {
                            id: data.manager.agency_id
                        })
                        this.selectedManager = data.manager
                        this.fetch()
                    })
                    .catch(e => {
                        console.log(e)
                        this.$store.dispatch('toastError', e)
                    })
            },
            fetch() {
                if (!this.selectedManager) {
                    return
                }

                this.loading = true
                this.getManager(this.selectedManager.id)
                    .then(() => {
                        this.setTitle(`Manager : ${this.manager.firstname} ${this.manager.lastname}`)

                        this.totalPaid = 0
                        this.totalContents = 0

                        this.manager.consultants.map(consultant => {
                            this.totalPaid += consultant.contents_sum_price_paid
                            this.totalContents += consultant.contents_count
                        })
                    })
                    .catch(e => {
                        this.$store.dispatch('toastError', e)
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },
            reloadClients() {
                this.$refs.clients.fetchData()
            },
            reload() {
                // this.$refs.managers.fetchData()
                // this.$refs.consultants.fetchData()
            }
        }
    }
</script>

<style></style>
